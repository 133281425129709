<template>
    <div class="layout-topbar clearfix">
        <button class="layout-topbar-logo p-link" @click="goDashboard">
            <img id="layout-topbar-logo" alt="babylon-layout" src="layout/images/logo-white.png" />
        </button>

        <button class="layout-menu-button p-link" @click="onMenuButtonClick">
            <i class="pi pi-bars"></i>
        </button>

        <button id="topbar-menu-button" class="p-link" @click="onTopbarMenuButtonClick">
            <i class="pi pi-ellipsis-v"></i>
        </button>

        <ul :class="topbarItemsClass">
            <!-- <li>{{ auth?.name }}</li> -->
            <li v-if="profileMode === 'popup' || horizontal" :class="['user-profile', { 'active-topmenuitem': activeTopbarItem === 'profile' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'profile' })">
                <button class="p-link">
                    <img alt="babylon-layout" src="layout/images/avatar.png" />
                    <span class="topbar-item-name">{{ auth?.name }}</span>
                </button>

                <transition name="layout-submenu-container">
                    <ul class="fadeInDown" v-show="activeTopbarItem === 'profile'">
                        <li role="menuitem">
                            <router-link to="/profile">
                                <button class="p-link">
                                    <i class="pi pi-user"></i>
                                    <span>{{ $t('common.profile') }}</span>
                                </button>
                            </router-link>
                        </li>
                        <!-- <li role="menuitem">
                            <button class="p-link">
                                <i class="pi pi-cog"></i>
                                <span>{{ $t('common.password') }}</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button class="p-link">
                                <i class="pi pi-envelope"></i>
                                <span>{{ $t('common.message') }}</span>
                            </button>
                        </li> -->
                        <li role="menuitem">
                            <button class="p-link" @click="logout">
                                <i class="pi pi-bell"></i>
                                <span>{{ $t('common.logout') }}</span>
                            </button>
                        </li>
                    </ul>
                </transition>
            </li>

            <!-- <li :class="[{ 'active-topmenuitem': activeTopbarItem === 'settings' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'settings' })">
                <button class="p-link">
                    <i class="topbar-icon pi pi-calendar"></i>
                    <span class="topbar-item-name">Notifications</span>
                </button>

                <transition name="layout-submenu-container">
                    <ul class="fadeInDown" v-show="activeTopbarItem === 'settings'">
                        <li role="menuitem">
                            <button class="p-link">
                                <i class="pi pi-tags"></i>
                                <span>Pending tasks</span>
                                <span class="topbar-submenuitem-badge">6</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button class="p-link">
                                <i class="pi pi-calendar-plus"></i>
                                <span>Meeting today at 3pm</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button class="p-link">
                                <i class="pi pi-download"></i>
                                <span>Download</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button class="p-link">
                                <i class="pi pi-lock"></i>
                                <span>Book flight</span>
                            </button>
                        </li>
                    </ul>
                </transition>
            </li>

            <li :class="[{ 'active-topmenuitem': activeTopbarItem === 'messages' }]" @click="$emit('topbar-item-click', { originalEvent: $event, item: 'messages' })">
                <button class="p-link">
                    <i class="topbar-icon pi pi-inbox"></i>
                    <span class="topbar-item-name">Messages</span>
                    <span class="topbar-badge">{{ depositCount }}</span>
                </button>

                <transition name="layout-submenu-container">
                    <ul class="fadeInDown" v-show="activeTopbarItem === 'messages'">
                        <li role="menuitem">
                            <button class="topbar-message p-link">
                                <img src="layout/images/avatar-john.png" alt="babylon-layout" />
                                <span>Give me a call</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button class="topbar-message p-link">
                                <img src="layout/images/avatar-julia.png" alt="babylon-layout" />
                                <span>Reports attached</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button class="topbar-message p-link">
                                <img src="layout/images/avatar-kevin.png" alt="babylon-layout" />
                                <span>About your invoice</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button class="topbar-message p-link">
                                <img src="layout/images/avatar-julia.png" alt="babylon-layout" />
                                <span>Meeting today</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button class="topbar-message p-link">
                                <img src="layout/images/avatar.png" alt="babylon-layout" />
                                <span>Out of office</span>
                            </button>
                        </li>
                    </ul>
                </transition>
            </li> -->
            <li>
                <locale-switcher />
            </li>
        </ul>
    </div>
</template>

<script>
import { useAuthStore } from '@/store';
import { computed } from 'vue';

import { useRouter } from 'vue-router';
import LocaleSwitcher from './LocaleSwitcher.vue';
export default {
    mounted() {},
    data() {
        return {
            depositCount: -1,
            received_messages: [],
            connected: false,
        };
    },

    setup() {
        const store = useAuthStore();
        const auth = computed(() => store.user);
        const router = useRouter();
        const logout = async () => {
            //console.log('logout...');
            await store.logout();
            router.replace('/login');
        };
        return { auth, logout };
    },

    props: {
        topbarMenuActive: Boolean,
        activeTopbarItem: String,
        profileMode: String,
        horizontal: Boolean,
    },

    methods: {
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
        goDashboard() {
            window.location = '/#/';
        },
        tickleConnection() {
            this.connected ? this.disconnect() : this.connect();
        },
    },
    computed: {
        topbarItemsClass() {
            return [
                'topbar-menu fadeInDown',
                {
                    'topbar-menu-visible': this.topbarMenuActive,
                },
            ];
        },
    },
    components: { LocaleSwitcher },
};
</script>
