// import NProgress from 'nprogress';
import { computed } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '../store';

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: () => import('../pages/Dashboard.vue'),
        meta: {
            breadcrumb: [{ parent: 'D100', label: 'D101' }],
            requireAuth: true,
        },
    },
    {
        path: '/report',
        name: 'report',
        component: () => import('../pages/report/report.vue'),
        meta: {
            breadcrumb: [{ label: 'R101' }],
            requireAuth: true,
        },
    },
    {
        path: '/reportdeposit',
        name: 'reportdeposit',
        component: () => import('../pages/report/reportdeposit.vue'),
        meta: {
            breadcrumb: [{ label: 'R102' }],
            requireAuth: true,
        },
    },
    {
        path: '/customer',
        name: 'customer',
        component: () => import('../pages/customer/customer.vue'),
        meta: {
            breadcrumb: [{ label: 'C101' }],
            requireAuth: true,
        },
    },
    {
        path: '/merchant',
        name: 'merchant',
        component: () => import('../pages/merchant/merchant.vue'),
        meta: {
            breadcrumb: [{ label: 'B101' }],
            requireAuth: true,
        },
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('../pages/profile/Index.vue'),
        meta: {
            breadcrumb: [{ label: 'Profile' }],
            requireAuth: true,
        },
    },
    {
        path: '/passwd-change',
        name: 'PasswdChange',
        component: () => import('../pages/profile/PasswdChange.vue'),
        meta: {
            breadcrumb: [{ label: 'Profile' }],
            requireAuth: true,
        },
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('../pages/admins/admin/AdminIndex.vue'),
        meta: {
            breadcrumb: [{ parent: 'A100', label: 'A101' }],
            requireAuth: true,
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../pages/Login.vue'),
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('../pages/Error.vue'),
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('../pages/NotFound.vue'),
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('../pages/Access.vue'),
    },
    {
        path: '/wizard',
        name: 'wizard',
        component: () => import('../pages/Wizard.vue'),
    },
    {
        path: '/:catchAll(.*)*',
        redirect: { name: 'access' },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return { left: 0, top: 0 };
    },
});

router.beforeEach(async (to, from, next) => {
    //console.log('Router Guard...');

    if (to.matched.some((record) => record.meta.requireAuth)) {
        const token = localStorage.getItem('token');
        if (!token) {
            next('/login');
        } else {
            const auth = useAuthStore();
            await auth.getProfile();
            const user = computed(() => auth.user);
            const menus = user.value.menus;
            // //console.log('menu = ', menu);

            let isAuth = false;
            menus.forEach((m) => {
                const items = m.items;
                items.forEach((item) => {
                    if (to.path === item.to || to.path == '/profile' || to.path == 'passwd-change') {
                        isAuth = true;
                    }
                    // //console.log(to.path + '=' + item.to);
                });
            });

            /**
             *  call user menu
             * if(to.path == menu[]) OK else /login
             * **/
            if (isAuth) next();
            else next('/access');
        }
    } else {
        // //console.log('Else Router Guard...');
        next();
    }
});

export default router;
