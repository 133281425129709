class TokenService {
    getLocalRefreshToken() {
        const token = JSON.parse(localStorage.getItem('token'));
        return token?.refreshToken;
    }

    getLocalAccessToken() {
        const token = JSON.parse(localStorage.getItem('token'));
        return token?.accessToken;
    }

    updateLocalAccessToken(token) {
        let user = JSON.parse(localStorage.getItem('token'));
        user.accessToken = token;
        localStorage.setItem('token', JSON.stringify(user));
    }

    getUser() {
        return JSON.parse(localStorage.getItem('token'));
    }

    setUser(token) {
        // //console.log(JSON.stringify(token));
        // localStorage.setItem('token', JSON.stringify(token));
        // localStorage.setItem('refresh_token', JSON.stringify(token.refreshToken));
        localStorage.setItem('token', JSON.stringify(token));
        // //console.log('token = ', JSON.stringify(token));
    }

    removeUser() {
        localStorage.removeItem('token');
        localStorage.removeItem('auth');
    }
}

export default new TokenService();
