import api from '@/service/api';

export async function useLogin(username, password) {
    return await api.post('/api/v1/auth/login', { username: username, password: password });
}

export async function useGetProfile() {
    return await api.get('/api/v1/auth/user');
}

export async function useLogout(userId) {
    //console.log('useLogout..');
    return await api.post('/api/v1/auth/logout', { userId: userId });
}
