import { defineStore } from "pinia";
import { useGetProfile } from '@/service/auth.service'
// import { useStorage } from '@vueuse/core'
import { useLogin } from "../service/auth.service";
// import tokenService from "../service/token.service";
import { useRouter } from 'vue-router';
import TokenService from "@/service/token.service";


export const useAuthStore = defineStore("auth", {

    state: () => {
        // initialize state from local storage to enable user to stay logged in
        return {
            loggedIn: false,
            user: {},
        };
    },

    actions: {
        async login(username, password) {
            const response = await useLogin(username, password);
            if (response.status == 200 && response.data.accessToken) {
                // const token = response.data;
                // useStorage("token", JSON.stringify(token));
                TokenService.setUser(response.data);
            }
        },

        async getProfile() {
            const token =  TokenService.getUser();
            if(token){
                const router = useRouter();
                const { data } = await useGetProfile();
                if (data.status == 'OK') {
                    if (data.user) {
                        // update pinia state
                        this.user = data.user;
                        this.loggedIn = true;
                        return this.user;
                    } else {
                        // update pinia state
                        this.user = {};
                        this.loggedIn = false;
                        this.logout();
                        router.push('/login');
                    }
                } else {
                    this.logout();
                    router.push('/login');
                }
            }
        },

        async logout() {
            await TokenService.removeUser();
            this.user = {};
            this.loggedIn = false
        },
    },

    persist: true,

});