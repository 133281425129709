export default {
  "0000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบทำรายการสำเร็จ"])},
  "0001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีข้อมูล"])},
  "0999": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["ระบบไม่สามารถทำรายการได้ ", _interpolate(_list(0))])},
  "2001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ชื่อเว็บไซด์"])},
  "2002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล อีเมล"])},
  "2003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลไม่ตรงตามรูปแบบ กรุณากำหนดใหม่"])},
  "2004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล เบอร์ติดต่อ"])},
  "2005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล Line ID"])},
  "2006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดเวลาเริ่มปิดระบบ"])},
  "2007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดเวลาเปิดระบบ"])},
  "2008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาระบุเหตุผล"])},
  "2009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ชื่อลิงค์"])},
  "2010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล รายละเอียด"])},
  "2011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล รูปภาพ"])},
  "2012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ลำดับ"])},
  "2013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล สถานะ"])},
  "2014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล กลุ่มที่"])},
  "2015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ชื่อโปรโมชั่น"])},
  "2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล อัตราส่วนโปรโมชั่น"])},
  "2017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล อัตราส่วนโปรโมชั่น ระหว่าง 1 ถึง 100"])},
  "2018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล จำกัดโปรโมชั่น"])},
  "2019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล เวลาสร้าง เริ่มจาก"])},
  "2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล เวลาสร้าง ถึง"])},
  "2021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล รายละเอียดของหมายเหตุ"])},
  "3001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล เบอร์ติดต่อ"])},
  "3002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ชื่อบัญชี"])},
  "3003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากรอกเลขที่บัญชีให้ถูกต้อง"])},
  "3004": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["มีรหัสผู้ใช้งาน ", _interpolate(_list(0)), " อยู่ในระบบแล้ว"])},
  "3005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน"])},
  "3006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล รหัสผ่านต้องเป็นตัวอักษรภาษาอังกฤษรวมกับตัวเลขอย่างน้อย 6 ตัวอักษร"])},
  "3007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล มือถือ"])},
  "3008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ชื่อธนาคาร"])},
  "3009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีข้อมูล ชื่อบัญชี ซ้ำกับรายการที่มีอยู่แล้ว"])},
  "3010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล เลขบัญชี"])},
  "3011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ชื่อกลุ่มสมาชิก"])},
  "3012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล อัตราส่วนพอยท์(%)"])},
  "3013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ฝากขั้นต่ำ"])},
  "3014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ฝากสูงสุด"])},
  "3015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝากสูงสุด ต้องมากกว่า ฝากขั้นต่ำ"])},
  "3016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดฝากขั้นต่ำ มากกว่าหรือเท่ากับ 50"])},
  "3017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ถอนขั้นต่ำ"])},
  "3018": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ถอนสูงสุด"])},
  "3019": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถอนสูงสุด ต้องมากกว่า ถอนขั้นต่ำ"])},
  "3020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดถอนขั้นต่ำ มากกว่าหรือเท่ากับ 50"])},
  "3021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล แลกขั้นต่ำ"])},
  "3022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล แลกสูงสุด"])},
  "3023": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แลกสูงสุด ต้องมากกว่า แลกขั้นต่ำ"])},
  "3024": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ผู้ดูแลพิเศษ"])},
  "3025": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล อีเมล"])},
  "3026": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมลไม่ตรงตามรูปแบบ กรุณากำหนดใหม่"])},
  "3027": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล Line ID"])},
  "3028": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล คอมขั้นต่ำ"])},
  "3030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล คอมสูงสุด"])},
  "3031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คอมสูงสุด ต้องมากกว่า คอมขั้นต่ำ"])},
  "3032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีข้อมูล ธนาคารและเลขที่บัญชี ซ้ำกับรายการที่มีอยู่แล้ว"])},
  "3033": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อผู้ใช้ต้องประกอบด้วยตัวอักษรภาษาอังกฤษ a-z หรือ ตัวเลขเท่านั้น จำนวนตัวอักษร 6-14 ตัวอักษร"])},
  "8001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ชื่อบัญชี(TH)"])},
  "8002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล เลขบัญชี(EN)"])},
  "8003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ชื่อบัญชี (แสดง)"])},
  "8004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล เลขที่บัญชี (แสดง)"])},
  "8005": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล สาขาของธนาคาร"])},
  "8006": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล สถานะ"])},
  "8007": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาระบุชื่อกลุ่มธนาคาร"])},
  "8008": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาเลือกสถานะ"])},
  "8009": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากำหนดข้อมูล ธนาคาร"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สวัสดีชาวโลก"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสนอโปรโมชั่นที่น่าสนใจให้กับท่านสมาชิก ดังนี้ โปรโมชั่นโบนัสเงินฝากครั้งแรก, โปรโมชั่นโบนัสต้อนรับสำหรับลูกค้าใหม่ นอกจากนั้นยังมีโปรโมชั่นเติมเงิน และโปรโมชั่นคืนเงิน ให้กับสมาชิกปัจจุบันอีกด้วย"])},
  "menu.home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าแรก"])},
  "menu.website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการเว็บไซต์"])},
  "menu.member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการสมาชิก"])},
  "menu.agent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตัวแทน"])},
  "menu.cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบเงินสด"])},
  "menu.cash.transfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การโอนย้ายเงิน"])},
  "menu.cash.adjust": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การปรับเปลี่ยนเงิน"])},
  "menu.report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงาน"])},
  "menu.admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการเจ้าหน้าที่"])},
  "menu.setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่า"])},
  "menu.website.setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่าทั่วไป"])},
  "menu.website.ma": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดปรับปรุง"])},
  "menu.website.ads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จัดการโฆษณา"])},
  "menu.website.promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โปรโมชั่น"])},
  "menu.member.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการสมาชิก"])},
  "menu.member.level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระดับสมาชิก"])},
  "menu.member.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มสมาชิก"])},
  "menu.agent.req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ขอเป็นตัวแทน"])},
  "menu.agent.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายกาตัวแทน"])},
  "menu.admin.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการเจ้าหน้าที่"])},
  "menu.bank.list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการบัญชี"])},
  "menu.group.bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มธนาคาร"])},
  "common.process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผลการทำงาน"])},
  "common.complete.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มข้อมูลสำเร็จ"])},
  "common.complete.update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขข้อมูลสำเร็จ"])},
  "common.complete.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบข้อมูลสำเร็จ"])},
  "common.complete.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปลี่ยนรหัสผ่านสำเร็จ"])},
  "common.confrim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยันการลบข้อมูล"])},
  "common.number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลำดับ"])},
  "common.username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีผู้ใช้งาน"])},
  "common.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ"])},
  "common.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัสผ่าน"])},
  "common.confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยืนยัน รหัสผ่าน"])},
  "common.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อีเมล์"])},
  "common.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สิทธิ์การใช้งาน"])},
  "common.status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะ"])},
  "common.createdOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่สร้าง"])},
  "common.createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สร้างโดย"])},
  "common.updatedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่แก้ไข"])},
  "common.updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขโดย"])},
  "common.item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการ"])},
  "common.totalItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนทั้งหมด"])},
  "common.btn.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม"])},
  "common.btn.del": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
  "common.btn.edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไข"])},
  "common.btn.detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด"])},
  "common.btn.saveEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไข"])},
  "common.btn.saveNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
  "common.statusY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช้งาน"])},
  "common.statusN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
  "common.profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลส่วนตัว"])},
  "common.logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ออกจากระบบ"])},
  "common.login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เข้าสู่ระบบ"])},
  "common.message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความ"])},
  "common.open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิดใช้งาน"])},
  "common.close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดใช้งาน"])},
  "common.isdefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าเริ่มต้น"])},
  "common.yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ใช่"])},
  "common.no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ใช่"])},
  "common.show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แสดง"])},
  "common.hidden": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ซ่อน"])},
  "common.general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดค่าทั่วไป"])},
  "common.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเว็บไซด์"])},
  "common.contactTel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เบอร์ติดต่อ"])},
  "common.lineId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Line ID"])},
  "common.facebookId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FACEBOOK_ID"])},
  "common.logoOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โลโก้เดิม"])},
  "common.logoNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โลโก้ใหม่"])},
  "common.uploadPic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัพโหลดรูปภาพ"])},
  "common.uploadSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกรูปภาพ"])},
  "common.uploadCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิกรูปภาพ"])},
  "common.dragAndDrop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลากและวางไฟล์มาที่นี่เพื่ออัปโหลด"])},
  "common.picOld": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปภาพเดิม"])},
  "common.picNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปภาพใหม่"])},
  "common.sysmaintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การบำรุงรักษาทั้งระบบ"])},
  "common.mainSystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระบบหลัก"])},
  "common.start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งแต่"])},
  "common.end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จนถึง"])},
  "common.reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เหตุผล"])},
  "common.reason.ma.close.temporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดชั่วคราว"])},
  "common.reason.ma.close.temporaryCanTransfer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดชั่วคราว (โอนย้ายปกติ)"])},
  "common.reason.ma.close.weeklyUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดปรับปรุงประจำสัปดาห์"])},
  "common.reason.ma.close.monthlyUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดปรับปรุงประจำเดือน"])},
  "common.maintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การบำรุงรักษาฝากถอน"])},
  "common.apimaintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การบำรุงรักษาเฉพาะ API"])},
  "common.gamename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อเกมส์"])},
  "common.statusmaintenance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สถานะการปิดปรับปรุง"])},
  "common.startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่เริ่ม"])},
  "common.toDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่ถึง"])},
  "common.on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เปิด"])},
  "common.off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิด"])},
  "common.transferOff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ปิดโอนย้าย"])},
  "common.mainads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โฆษณาหลัก"])},
  "common.banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แบนเนอร์"])},
  "common.advertise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อักษรวิ่ง"])},
  "common.msgwelcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อความต้อนรับ"])},
  "common.linkname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อลิ้งค์"])},
  "common.lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ภาษา"])},
  "common.edittime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขเวลา"])},
  "common.groupNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่มที่"])},
  "common.detailWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด (Web)"])},
  "common.detailMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียด (Mobile)"])},
  "common.subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หัวข้อ"])},
  "common.picWeb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปภาพ (Web)"])},
  "common.picMobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รูปภาพ (Mobile)"])},
  "common.groupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลุ่ม"])},
  "common.access.role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กำหนดสิทธิ์การเข้าถึง"])},
  "common.menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายการเมนู"])},
  "common.next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถัดไป"])},
  "common.back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับ"])},
  "common.slect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลือกรายการ"])},
  "common.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประเภท"])},
  "common.icon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["icon"])},
  "common.grade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["grade"])},
  "common.desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำอธิบาย"])},
  "common.depositMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝากขั้นต่ำ"])},
  "common.depositMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝากสูงสุด"])},
  "common.withdrawMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถอนขั้นต่ำ"])},
  "common.withdrawMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถอนสูงสุด"])},
  "common.pointMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แลกขั้นต่ำ"])},
  "common.pointMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แลกสูงสุด"])},
  "common.adjustMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มขั้นต่ำ"])},
  "common.adjustMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสูงสุด"])},
  "common.commMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คอมขั้นต่ำ"])},
  "common.commMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คอมสูงสุด"])},
  "common.commision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คอมมิชชั่น"])},
  "common.adjustMoney": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มเงิน"])},
  "common.redeemPoin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แลกคะแนน"])},
  "common.bank": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ธนาคาร"])},
  "common.logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["โลโก้"])},
  "common.ฺbankDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลธนาคาร"])},
  "common.bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อบัญชี"])},
  "common.bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขที่บัญชี"])},
  "common.bankBranch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สาขาบัญชี"])},
  "common.bankGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อกลุ่มธนาคาร"])},
  "common.total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ทั้งหมด"])},
  "common.promotionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อโปรโมชั่น"])},
  "common.promotionRatio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["อัตราส่วนโปรโมชั่น (%)"])},
  "common.limitPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำกัดโปรโมชั่น"])},
  "common.editPromotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขโปรโมชั่น"])},
  "common.category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมวดหมู่"])},
  "common.firstDept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนวนเฉพาะฝากครั้งแรก"])},
  "common.min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝากขั้นต่ำ"])},
  "common.max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฝากสูงสุด"])},
  "common.detailRamark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายละเอียดของหมายเหตุ"])},
  "common.suspend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระงับ"])},
  "common.group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลุ่ม"])},
  "common.level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระดับ"])},
  "common.currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สกุลเงิน"])},
  "common.mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มือถือ"])},
  "common.balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนเงิน"])},
  "common.dataDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลทั่วไป"])},
  "common.addMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่มสมาชิก"])},
  "common.editMember": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขข้อมูลสมาชิก"])},
  "common.grades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เกรด"])},
  "common.editpassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["แก้ไขรหัสผ่าน"])},
  "common.hatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตู้ฟักไข่"])},
  "common.breeder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พ่อพันธุ์แม่พันธุ์"])},
  "common.quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนไข่"])},
  "common.note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเหตุ"])},
  "common.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
  "common.delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลบ"])},
  "common.save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก"])},
  "common.classify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คัดแยกไข่"])},
  "common.totalw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนไข่"])},
  "common.distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ผ่านเกณฑ์"])},
  "common.date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วันที่"])},
  "common.qtclassified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำนวนไข่ที่คัดแยก"])},
  "common.qtqualifying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่านเกณฑ์"])},
  "common.qtunqualified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่ผ่านเกณฑ์"])},
  "common.egghatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ผ่านเกณฑ์"])},
  "common.menudistribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำหน่าย/เสีย"])},
  "common.sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["จำหน่าย"])},
  "common.broken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เสีย"])},
  "common.collect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เก็บไข่"])},
  "common.add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพิ่ม"])},
  "common.eggsystem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลไข่"])},
  "common.incubator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตู้ส่องเชื้อ"])},
  "common.cabinet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตู้เกิด"])},
  "common.lot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ล๊อต"])},
  "common.day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วัน"])},
  "common.egg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไข่"])},
  "common.unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ฟอง"])},
  "common.infected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["มีเชื้อ"])},
  "common.unleavened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่มีเชื้อ"])},
  "common.complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สมบูรณ์"])},
  "common.incomplete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไม่สมบูรณ์"])},
  "D100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
  "D101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หน้าแรก"])},
  "R100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงาน"])},
  "R101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงาน"])},
  "R102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รายงานสุธิ"])},
  "C100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลลูกค้า"])},
  "C101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้า"])},
  "C102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ลูกค้าอื่น"])},
  "B100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อมูลบัญชี"])},
  "B101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีหมุนเวียนหลัก"])},
  "B102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บัญชีลูกค้า"])},
  "H100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ประวัติรายการฝาก"])},
  "W100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ถอนเงิน"])},
  "F100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่า"])},
  "C107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตั้งค่า"])},
  "A100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เจ้าหน้าที่"])},
  "A101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["พนักงาน"])}
}