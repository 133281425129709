<template>
    <!-- <select v-model="$i18n.locale">
        <option v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale">
            {{ locale }}
        </option>
    </select> -->

    <Dropdown v-model="$i18n.locale" :options="locales" optionLabel="locale" placeholder="Select a Country">
        <template #value="slotProps">
            <div class="lang-item lang-item-value" v-if="slotProps.value">
                <img :src="`./images/flag/${slotProps.value}.png`" />
            </div>
            <span v-else>
                {{ slotProps.placeholder }}
            </span>
        </template>
        <template #option="slotProps">
            <div class="lang-item">
                <img :src="`./images/flag/${slotProps.option}.png`" />
            </div>
        </template>
    </Dropdown>
</template>

<script>
export default {
    name: "LocaleSwitcher",
    data() {
        return { locales: ["th", "en"] };
    },
};
</script>

<style lang="scss" scoped>
.p-dropdown {
    margin-top: 5px;
    background: #ffffff;
    border: 0px;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 3px;
}

.lang-item {
    img {
        // width: 17px;
        margin-right: 0.5rem;
    }
}
</style>