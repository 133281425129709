// import { useRouter } from 'vue-router';
import axiosInstance from './api';
import TokenService from './token.service';
import NProgress from 'nprogress';
// import AuthService from '@/services/auth.service';

const setup = (store) => {
    axiosInstance.interceptors.request.use(
        (config) => {
            NProgress.start();
            const token = TokenService.getLocalAccessToken();
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token; // for Spring Boot back-end
                // config.headers["x-access-token"] = token; // for Node.js Express back-end
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosInstance.interceptors.response.use(
        (res) => {
            NProgress.done();
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (originalConfig.url !== '/api/v1/auth/login' && err.response) {
                // Access Token was expired
                if (err.response.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;
                    //console.log('getLocalRefreshToken : ', TokenService.getLocalRefreshToken());

                    // const router = useRouter();

                    try {
                        const rs = await axiosInstance.post('/api/v1/auth/refreshtoken', {
                            refreshToken: TokenService.getLocalRefreshToken(),
                        });

                        const { accessToken } = rs.data;

                        // //console.log('rs = ', rs);

                        store.dispatch('auth/refreshToken', accessToken);
                        TokenService.updateLocalAccessToken(accessToken);

                        return axiosInstance(originalConfig);
                    } catch (_error) {
                        TokenService.removeUser();
                        // //console.log('error : ', _error);
                        return Promise.reject(_error);
                    }
                }
            }
            NProgress.done();
            return Promise.reject(err);
        }
    );
};

export default setup;
